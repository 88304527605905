import { lazy, Suspense } from 'react'
import * as ReactDOM from 'react-dom'
import './index.css'
import LoadingSpinner from './components/LoadingSpinner'
import * as serviceWorker from './serviceWorker'
import ErrorBoundary from './components/ErrorBoundary'
import GlobalStyles from './style'
import { requestPermission, configureNotifications } from './notifications'
import { SnackbarProvider } from 'notistack'

requestPermission()
configureNotifications()

export const render = (
  Component: any,
  el = document.getElementById('root') as HTMLElement
) => {
  ReactDOM.render(
    <ErrorBoundary>
      <SnackbarProvider maxSnack={5}>
        <Suspense fallback={<LoadingSpinner />}>
          <GlobalStyles />
          <Component env={window.env} />
        </Suspense>
      </SnackbarProvider>
    </ErrorBoundary>,
    el
  )
}

if (process.env.NODE_ENV !== 'test') {
  const App = lazy(() => import('./components/App'))
  render(App)
}

if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
  ;(module as any).hot.accept('./components/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./components/App').default
    render(NextApp)
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
