// tslint:disable-next-line:one-variable-per-declaration
let hidden, visibilityChange

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
}
// @ts-ignore
else if (typeof document.mozHidden !== 'undefined') {
  hidden = 'mozHidden'
  visibilityChange = 'mozvisibilitychange'
}
// @ts-ignore
else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
}
// @ts-ignore
else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

export const addVisibilityListener = (callback) => {
  document.addEventListener(visibilityChange, callback, false)
}

export const addShownListener = (callback) => {
  document.addEventListener(visibilityChange, () => {
    if (!document[hidden]) {
      callback()
    }
  })
}

export const addHiddenListener = (callback) => {
  document.addEventListener(visibilityChange, () => {
    if (document[hidden]) {
      callback()
    }
  })
}

export const isHiddenVisibility = () => document[hidden]
