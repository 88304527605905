import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

const SpinnerHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props: LoadingSpinnerProps) => (!props.noMargin ? '2rem 0' : '0')};
  color: #4a4a4a;
`

interface LoadingSpinnerProps {
  size?: number
  noMargin?: boolean
  color?: 'inherit' | 'primary' | 'secondary'
}

const LoadingSpinner = ({ size, noMargin, color }: LoadingSpinnerProps) => (
  <SpinnerHolder noMargin={noMargin} data-testid="loading-spinner">
    <CircularProgress size={size || 40} color={color || 'secondary'} />
  </SpinnerHolder>
)

export default LoadingSpinner
