// From Mozilla docs:
// https://developer.mozilla.org/en/docs/Web/API/notification#Example

import {isHiddenVisibility} from './visibility'

let notificationsSupported = true
let permissionRequested = false
const notificationQueue = []
const openNotifications = []
let n

if (!('Notification' in window)) {
  notificationsSupported = false
}

export const NOTIFICATION_EVENTS = {
  click: 'onclick',
  error: 'onerror',
}

let config = {
  requireInteraction: false,
  onlyWhenInactive: true,
}

export const configureNotifications = (options?) => {
  config = Object.assign({}, options ? options : {})
  if (!notificationsSupported) {
    return
  }

  if (!permissionRequested && Notification.permission !== 'granted') {
    requestPermission()
  }
}

export const simpleNotification = (title, text) => {
  const options = Object.assign({}, config, {body: text})

  if (
    !notificationsSupported ||
    Notification.permission === 'denied' ||
    (!isHiddenVisibility() && document.hasFocus())
  ) {
    return
  }

  if (!permissionRequested && Notification.permission === 'default') {
    requestPermission()
    // @ts-ignore
    notificationQueue.push({title, options})
  } else if (Notification.permission === 'default') {
    // @ts-ignore
    notificationQueue.push({title, options})
  } else {
    makeNotification(title, Object.assign({}, config, options))
  }
}

interface NotificationOptions {
  body?: string
  title: string
  icon?: string | null
  timestamp: number
  click?: () => void
}

export const createNotification = (options: NotificationOptions) => {
  if (
    !notificationsSupported ||
    Notification.permission === 'denied' ||
    (config.onlyWhenInactive && !isHiddenVisibility() && document.hasFocus())
  ) {
    return
  }

  if (!permissionRequested && Notification.permission !== 'granted') {
    requestPermission()
    // @ts-ignore
    notificationQueue.push({title: options.title, options})
  } else if (Notification.permission === 'default') {
    // @ts-ignore
    notificationQueue.push({title: options.title, options})
  } else {
    makeNotification(options.title, Object.assign({}, config, options))
  }
}

const makeNotification = (title, options) => {
  n = new Notification(title, options)

  Object.values(NOTIFICATION_EVENTS).map(eventName => {
    if (typeof options[eventName] === 'function') {
      n[NOTIFICATION_EVENTS[eventName]] = ((fn) => {
        return function () {
          fn.apply(n, Array.prototype.slice.call(arguments, 0))
        }
      })(options[eventName])
    }
    return true
  })

  // @ts-ignore
  openNotifications.push(n)
}

const clearNotificationQueue = () => {
  for (let i = 0; i < notificationQueue.length; i++) {
    makeNotification(
      // @ts-ignore
      notificationQueue[i].options.title,
      // @ts-ignore
      notificationQueue[i].options
    )
  }
}

export const requestPermission = (callback?: (params?) => void) => {
  if (!notificationsSupported) {
    return
  }

  // Let's check whether notification permissions have already been granted
  if (Notification.permission === 'granted') {
    return
  }
  // Otherwise, we need to ask the user for permission
  else if (!permissionRequested) {
    Notification.requestPermission((permission) => {
      if (permission === 'denied') {
        return
      } else if (permission === 'granted') {
        clearNotificationQueue()
      }

      permissionRequested = false

      if (typeof callback === 'function') {
        callback(permission)
      }
    })
  }

  permissionRequested = true
}

export const closeNotification = () => {
  if (n) {
    n.close()
    n = null
  }
  return
}
