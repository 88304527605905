import * as React from 'react'
import ErrorPage from '../ErrorPage'

interface State {
  error: Error | undefined
}

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      error: undefined,
    }
  }

  public componentDidCatch(error: Error) {
    this.setState({
      error,
    })
  }

  public render() {
    const { error } = this.state
    const { children } = this.props
    return error === undefined ? (
      children
    ) : (
      <ErrorPage message={error.toString()} />
    )
  }
}

export default ErrorBoundary
