import * as React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  background: darkred;
  height: 100vh;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  color: white;
  line-height: 1;
`

const H1 = styled.h1`
  margin: 0 0 1rem;
  font-size: 3.5rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`

const H2 = styled.h2`
  margin: 0;
  font-size: 2rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`

interface Props {
  message?: string
}

const ErrorPage: React.SFC<Props> = ({ message }) => (
  <Wrapper>
    <H1>Oops, something broke.</H1>
    <H2>{message}</H2>
  </Wrapper>
)

ErrorPage.defaultProps = {
  message: 'Something is broken...',
}

export default ErrorPage
